<template>
    <div>
        <loading-card v-show="loading"></loading-card>
        <b-card v-show="!loading" no-body>
            <filter-block>
                <template #header-left>
                    <span class="text-large font-weight-bold">Организации</span>
                </template>
                <template #filters>
                    <filter-text label="ФИО" placeholder="Введите ФИО" v-model="filter.name" @input="onFilterChange" />
                    <filter-text label="Email" placeholder="Введите email" v-model="filter.email" @input="onFilterChange" />
                    <filter-text label="Название организации/ИНН/рег.номер" placeholder="Введите название организации/ИНН/рег.номер" v-model="filter.organization" @input="onFilterChange" />
                </template>
            </filter-block>
            <b-card-body v-show="!loading">
                <div class="table-responsive">
                    <vuetable
                        ref="vuetable"
                        :fields="columns"
                        :per-page="perPage"
                        :css="vuetable2Config.table"
                        :sort-order="paginationInfo.sortOrder"
                        :load-on-start="false"
                        :query-params="tableQueryParams"
                        :append-params="filter"
                        :http-fetch="fetchData"
                        @vuetable:pagination-data="onPaginationData"
                        api-url="/admins"
                        data-path="data"
                        pagination-path="meta"
                        no-data-template="Нет данных">
                        <div slot="name" slot-scope="props">
                            <router-link :to="{ name: 'OrganizationEdit', params: { id: props.rowData.id } }">
                                {{ props.rowData.name }}
                            </router-link>
                        </div>
                        <div slot="registration_date" slot-scope="props" style="text-align: right">
                            {{ getDateFormat(props.rowData.registration_date) }}
                        </div>
                        <div slot="roles" slot-scope="props">
                            <ul class="my-0 py-0">
                                <li v-for="(role, index) in props.rowData.roles" :key="index">{{ role }}</li>
                            </ul>
                        </div>
                        <div slot="created_at" slot-scope="props" style="text-align: right">
                            {{ getDateFormat(props.rowData.created_at) }}
                        </div>
                        <div slot="actions" slot-scope="props" class="float-right">
                            <b-dropdown boundary="window" id="dropdown-1" text="Действия" size="sm" right v-if="userRoleIsPermitted([AdminRole.SUPER])">
                                <b-dropdown-item :to="{ name: 'OrganizationEdit', params: { id: props.rowData.id } }" v-if="userRoleIsPermitted([AdminRole.SUPER])">Редактировать </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </vuetable>
                </div>
                <div style="margin-top: 10px" class="float-right d-flex">
                    <vuetable-pagination ref="pagination" :css="vuetable2Config.pagination" @vuetable-pagination:change-page="onChangePage" />
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import FilterBlock from '@/elements/filter-block';

export default {
    name: 'OrganizationsCustomersList',
    components: { FilterBlock },
    metaInfo: {
        title: 'Организации',
    },
    data() {
        return {
            columns: [
                { name: 'id', sortField: 'id', title: '#', width: '100px' },
                { name: 'name', sortField: 'name', title: 'Название организации' },
                { name: 'inn', sortField: 'inn', title: 'ИНН' },
                { name: 'registration_date', title: 'Дата регистрации в ФНС' },
                { name: 'roles', title: 'Роли организации' },
                { name: 'created_at', title: 'Дата регистрации в ИС' },
                { name: 'actions', title: 'Действия', width: '100px' },
            ],
            isFiltersVisible: false,
            filter: this.$store.state.admins.filter,
            paginationInfo: this.$store.state.admins.pagination,
            loading: true,
            perPage: 20,
            exportLoading: false,
        };
    },
    async mounted() {
        // await this.fillFilters();
        await this.fillData();
        this.loading = false;
    },
    methods: {
        async fillData() {
            this.loading = true;

            await this.$refs.vuetable.refresh();
            await this.$refs.vuetable.changePage(this.paginationInfo.page);
            this.loading = false;
        },
        fetchData(apiUrl, httpOptions) {
            return this.$http.get(apiUrl, httpOptions);
        },
        tableQueryParams(sortOrder, currentPage, perPage) {
            this.paginationInfo.page = currentPage;
            this.$store.commit('admins/updatePagination', this.paginationInfo);
            return {
                sortField: sortOrder[0].field,
                sortOrder: sortOrder[0].direction,
                page: currentPage,
                per_page: perPage,
            };
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.paginationInfo.page = page;
            this.$refs.vuetable.changePage(page);
        },
        onFilterChange() {
            this.$store.commit('admins/updateFilter', this.filter);
            this.$refs.vuetable.refresh();
        },
    },
};
</script>
