<template>
    <div v-if="!loading">
        <h4 class="d-flex justify-content-between align-items-center font-weight-bold py-3 mb-4">Редактирование - Организации</h4>
        <b-card no-body>
            <b-card-body class="pb-2">
                <form-row-edit-text v-model="$v.admin.name.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.name" label="Полное наименование" />
                <form-row-edit-date v-model="$v.admin.registration_date.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.registration_date" label="Дата регистрации в налоговом органе" />
                <form-row-edit-text v-model="$v.admin.registration_number.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.registration_number" label="Регистрационный номер" />
                <form-row-edit-text v-model="$v.admin.inn.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.inn" label="ИНН" />
                <form-row-edit-text v-model="$v.admin.okpo.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.okpo" label="ОКПО" />
                <form-row-edit-text v-model="$v.admin.head.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.head" label="ФИО руководителя" />
                <form-row-multi-select v-model="$v.admin.roles.$model" :options="$globalDictionaries.adminRoles" :v="$v.admin.roles" label="Роли организации" label-field="title" />
                <form-row-edit-text v-model="$v.admin.contacts.legal_address.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.contacts.legal_address" label="Юридический адрес" />
                <form-row-edit-text v-model="$v.admin.contacts.postal_address.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.contacts.postal_address" label="Почтовый адрес" />
                <form-row-edit-text v-model="$v.admin.contacts.email.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.contacts.email" label="Email" />
                <form-row-edit-text v-model="$v.admin.contacts.website.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.contacts.website" label="Сайт" />
                <form-row-edit-text v-model="$v.admin.contacts.phone.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.contacts.phone" label="Телефон" />
                <form-row-edit-text v-model="$v.admin.contacts.fax.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.contacts.fax" label="Факс" />
                <form-row-edit-text v-model="$v.admin.contacts.contact_person_name.$model" :disabled="!userRoleIsPermitted([AdminRole.SUPER])" :v="$v.admin.contacts.contact_person_name" label="ФИО контактного лица" />
            </b-card-body>
            <b-card-footer v-if="userRoleIsPermitted([AdminRole.SUPER])">
                <div class="text-right">
                    <b-button :disabled="$v.$invalid || !$v.$anyDirty" type="submit" variant="primary" @click="onSubmit"> Сохранить</b-button>&nbsp;
                    <b-button variant="default" @click="onCancel"> Отмена</b-button>
                </div>
            </b-card-footer>
        </b-card>
    </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';

export default {
    name: 'adminEdit',
    metaInfo: {
        title: 'Редактирование - Организации',
    },
    data() {
        return {
            admin: {
                name: '',
                inn: '',
                okpo: '',
                head: '',
                registration_number: '',
                registration_date: null,
                roles: [],
                contacts: {
                    legal_address: '',
                    postal_address: '',
                    email: '',
                    website: '',
                    phone: '',
                    fax: '',
                    contact_person_name: '',
                },
            },
            loading: true,
        };
    },
    validations: {
        admin: {
            name: { required },
            inn: { required },
            okpo: { required },
            registration_number: { required },
            registration_date: { required },
            head: { required },
            roles: { required },
            contacts: {
                legal_address: { required },
                postal_address: { required },
                email: { required, email },
                website: {},
                phone: { required },
                fax: {},
                contact_person_name: { required },
            },
        },
    },
    mounted() {
        this.fillData().then(() => {
            setTimeout(() => {
                this.loading = false;
            }, 100);
        });
    },
    methods: {
        async fillData() {
            this.admin = await this.$api.admins.get(this.$route.params.id);
        },
        async onSubmit() {
            if (await this.$api.admins.put(this.$route.params.id, this.admin)) {
                this.onCancel();
            }
        },
        onCancel() {
            this.$router.push({ name: 'adminsList' });
        },
    },
};
</script>
